import {useEffect, useState} from "react"

import Start from "../Start";
import Footer from '../Footer';
import AboutUs from '../AboutUs';
import Privacy from '../Privacy';
import Terms from '../Terms';

import {setPageMainIndexToCookie} from "../../config/helpers";

import styles from './Main.module.scss';

const Main = () => {

  useEffect(() => {
    setPageMainIndexToCookie();
  } , [])

  const [footerLinksState, setFooterLinksState] = useState({
      aboutUs: false,
      privacy: false,
      terms: false,
  });
  const closeFooterLinks = () => {
      setFooterLinksState({
          aboutUs: false,
          privacy: false,
          terms: false,
      })
  }

  return (
    <>
        <div className={styles.bg} />
        <div className={styles.main}>
          <Start />
        </div>
        <Footer setFooterLinksState={setFooterLinksState}/>
        <AboutUs closeFooterLinks={closeFooterLinks} shouldShow={footerLinksState.aboutUs}/>
        <Privacy closeFooterLinks={closeFooterLinks} shouldShow={footerLinksState.privacy}/>
        <Terms closeFooterLinks={closeFooterLinks} shouldShow={footerLinksState.terms}/>
    </>
  );
};

export default Main;
