import React from "react"
import ReactDOM from "react-dom/client"
import Main from "./components/Main/Main"

import 'normalize.css';

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
     <Main/>
)
