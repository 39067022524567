import tt from '../../utils/tt';

import closeIcon from '../../images/close.svg';

import styles from './Terms.module.scss';

const Terms = ({shouldShow, closeFooterLinks}) => {
  return shouldShow && (
    <div className={styles.terms}>
        <img className={styles.closeIcon} src={closeIcon} onClick={closeFooterLinks} alt="close" />
        <h2>{tt(`{{ _('Terms of use') }}`)}</h2>
        <p>{tt(`{{ _('These terms of use agreement (“Terms of Use”) governs your use of the website Chooseyourdating.com (the “Website”). If You do not agree to these Terms of Use, You may not use the Website. You must be of legal age (over eighteen years of age in the United States) to use the Website.') }}`)}</p>
        <p>{tt(`{{ _('1) Acceptance of the Terms of Use. Any use of the Website, including viewing content, posting material on the Website or emailing content to the operators (“We” or “Us”) is governed under these Terms of Use. The most recent version of the Terms of Use will always appear on the Website and You will always be bound by the most recent Terms of Use, even if you are not notified of any changes. This version of the Terms of Use was modified on 1 November 2009.') }}`)}</p>
        <p>{tt(`{{ _('2) Privacy Policy. The Privacy Policy appearing on the Website is a part of these Terms of Use and incorporated into the Terms of Use by reference.') }}`)}</p>
        <p>{tt(`{{ _('3) Use of the Website.') }}`)}</p>
        <p>{tt(`{{ _('a) You may be required to create a username/password combination to use portions or sections of the Website. You agree and understand that You are solely responsible for maintaining the confidentiality of any username/password combination You create. You will be held accountable for any postings or actions taken on the Website for any access granted to the Website based upon Your username/password combination. If you become aware of any abuse of Your username/password combination, contact Us immediately using the contact information on the Website.') }}`)}</p>
        <p>{tt(`{{ _('b) You agree only to use the Website as set forth in these Terms of Use and according to applicable laws and regulations. You are responsible for any content, postings and comments which you may submit to the Website. You are responsible for any of Your actions which breach or could be reasonably construed as a breach of these Terms of Use and You may be held responsible for any damages caused by such breach. You may not use the Website in a manner which would disrupt the use of the Website by other users or persons. We reserve the right to investigate any breach or alleged breach of these Terms of Use and to report any breach or alleged breach of these Terms of Use to law enforcement authorities.') }}`)}</p>
        <p>{tt(`{{ _('c) We may prevent you from using the Website if We become aware of any action which breaches or which be reasonably construed as a breach of these Terms of Use. We may also limit your use of the Website for any other reason and retain the right to terminate Your use of the Website at our sole discretion. We reserve the right to refuse to accept, post, display or transmit any content submitted to the Website.') }}`)}</p>
        <p>{tt(`{{ _('d) We have no responsibility to store or maintain any content submitted to or posted on the Website and will not be held liable for any failure to store or maintain any such content.') }}`)}</p>
        <p>{tt(`{{ _('4) Copyrighted Information. You may not post or submit any proprietary information of another party which is protected under copyright or trademark law. We will respond to any notice we receive alleging an infringement of the copyright or trademark rights of a third party by investigating the allegation and removing the alleged infringing content should we determine that an infringement has occurred. Certain trademarks on BestDatingSitesRating.com are proprietary to those third parties who own such marks and we make no claim of ownership to such marks.') }}`)}</p>
        <p>{tt(`{{ _('5) Third Party Links. The Website will include links to websites operated by third parties (“Third Party Websites”) as well as reviews and commentary on Third Party Websites. Such links may be posted by Us, You, other users, Google Adsense or other parties. We have no control over any Third Party Websites that may be linked from the Website. We are not liable for any losses or damages You may incur by navigating to any Third Party Websites which may be linked from the Website. We may earn commissions through affiliate programs operated by Third Party Websites. Our membership in any affiliate program operated by Third Party Websites is not an endorsement of any such Third Party Websites.') }}`)}</p>
        <p>{tt(`{{ _('6) WARRANTY DISCLAIMER.') }}`)}</p>
        <p>{tt(`{{ _('a) WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND REGARDING THE WEBSITE, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. THE WEBSITE IS PROVIDED “AS-IS” AND YOU ARE TO USE THE WEBSITE SOLELY AT YOUR OWN RISK. YOU WILL BE RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM(S) WHICH OCCURS AS A RESULT OF YOUR USE OF THE WEBSITE. WE MAKE NO WARRANTY THAT THE WEBSITE WILL BE AVAILABLE ON A CONTINUOUS BASIS.') }}`)}</p>
        <p>{tt(`{{ _('b) WE MAKE NO WARRANTY THAT ANY INFORMATION AVAILABLE ON THE WEBSITE IS TRUE, RELIABLE OR ACCURATE. WE MAKE NO WARRANTY THAT WE WILL CORRECT ANY ERRORS, DEFECTS OR OMISSIONS ON THE WEBSITE. We make no warranty that reviews of Third Party Websites are factually accurate; Our reviews of Third Party Websites are based on our experience with such Third Party Websites, input from other parties and other research which we may have performed. We make no warranty that You will find any Third Party Websites to be satisfactory or to provide any results which you may seek from such Third Party Websites.') }}`)}</p>
        <p>{tt(`{{ _('7) LIMITATION OF LIABILITY.') }}`)}</p>
        <p>{tt(`{{ _('a) YOU EXPRESSLY UNDERSTAND AND AGREE THAT WE SHALL NOT BE LIABLE FOR ANY DAMAGES OR INTANGIBLE LOSS, WHETHER DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL OR ANY LOST PROFITS, LOST JOBS OR CONSULTING ARRANGEMENTS WHICH YOU MAY INCUR OR SUFFER AS A RESULT OF YOUR USE OF THE WEBSITE OR YOUR RELIANCE ON ANY INFORMATION ON THE WEBSITE OR YOUR DEALINGS WITH ANY THIRD PARTY WEBSITE WHICH MAY BE LINKED TO OR FROM THE WEBSITE, INCLUDING ANY SUCH DAMAGES OR LOSSES WHICH INCUR AS A RESULT OF YOUR FAILURE TO PROTECT YOUR USERNAME/PASSWORD COMBINATION. THIS LIMITATION OF LIABILITY SHALL NOT BE MODIFIED EVEN IF WE HAVE BEEN ADVISED OR MADE AWARE OF THE POSSIBILITY OF ANY SUCH DAMAGES OR LOSSES.') }}`)}</p>
        <p>{tt(`{{ _('b) WE SHALL NOT BE LIABLE TO YOU SHOULD YOU LOSE YOUR JOB, CONSULTING ARRANGEMENT OR SUFFER ANY OTHER DAMAGES RELATED TO YOUR CAREER, PROFESSION, REPUTATION, MARITAL OR RELATIONSHIP STATUS DUE TO YOUR USE OF THE WEBSITE. IF YOU BELIEVE THAT SUCH A RISK MAY EXIST, YOU SHOULD NOT USE THE WEBSITE.') }}`)}</p>
        <p>{tt(`{{ _('8) Indemnification. You agree to hold Us harmless and indemnify Us from and against any third-party claim arising from or in any way related to Your use of the Website, including any liability or expense arising from all claims, losses, damages (actual and consequential), suits, judgments, litigation costs and attorneys’ fees, of every kind and nature.') }}`)}</p>
        <p>{tt(`{{ _('9) Content Policy.') }}`)}</p>
        <p>{tt(`{{ _('a) You acknowledge that the Website is for entertainment purposes only and may contain content that you deem or find offensive or objectionable and that you use the Website solely at Your own risk.') }}`)}</p>
        <p>{tt(`{{ _('b) We do not and will not actively monitor the Website and any content that you submit to the Website. We take no responsibility for any content, including any malicious software programs, which are posted or submitted to the Website or any damages that such content may cause to You. It is Your responsibility to review any content which You submit to the Website. We do not endorse or support any content which is submitted to the Website.') }}`)}</p>
        <p>{tt(`{{ _('c) You represent and warrant that you have all the rights, power and authority necessary to submit your content to the Website.') }}`)}</p>
        <p>{tt(`{{ _('d) We do, however, reserve the right to review and/or remove any content submitted to the Website by You or other users which contains or references (1) pornography or obscene materials, (2) pedophilia, incest or bestiality; (3) threats of violence; (4) hateful references to a race, ethnic group, religion, disabled persons, gender, age or sexual orientation; (5) violates the copyright, trademark or other proprietary rights of another; (6) the private or confidential information of another person; and (7) Spam, marketing for other services or products, malicious software code (such as viruses or malware).') }}`)}</p>
        <p>{tt(`{{ _('e) We retain the right to retain an archived copy of any removed content or to report any removed content, or content deemed to contain or reference any materials referenced in Section 9(d)(1)-(7), above, to law enforcement authorities or to comply with applicable laws or regulations. We may also retain such content in order to preserve our rights.') }}`)}</p>
        <p>{tt(`{{ _('10) Proprietary Nature of Website.') }}`)}</p>
        <p>{tt(`{{ _('a) You acknowledge that We own all right, title and interest in and to the Website and such rights are protected by U.S. and international intellectual property laws. Accordingly, You agree that you will not reproduce, reverse engineer, copy, alter, modify, or create derivative works from the Website, including any content posted on the Website. You also agree that you will not use any robot, spider, other software or manual process to monitor or copy any content available on the Website.') }}`)}</p>
        <p>{tt(`{{ _('b) The logos on the website and the marks “Dating-Reviews-Guide.com” and “Dating Reviews Guide” are Our trademarks and are protected under U.S. and international intellectual property laws.') }}`)}</p>
        <p>{tt(`{{ _('c) Nothing in these Terms of Use gives you any right or license to use any of Our proprietary information, including our trademarks referenced above.') }}`)}</p>
        <p>{tt(`{{ _('11) Your Rights in and to Submitted Content.') }}`)}</p>
        <p>{tt(`{{ _('a) You retain the rights to any content you submit or post to or display on the Website. By submitting, posting or displaying content on or to the Website, you grant Us a perpetual, worldwide, non-exclusive and royalty-free license to reproduce, publish, create derivative works from, public display and distribute such content on the Website, even if We receive compensation or remuneration through Our operation of the Website, or any derivative works prepared from the Website or any content submitted, posted or displayed on the Website.') }}`)}</p>
        <p>{tt(`{{ _('b) You understand and acknowledge that We may transmit or distribute content submitted to the Website over various public networks and in various media; and make such changes to content as necessary to conform and adapt content to the Website. You understand and acknowledge that We may make content available to other companies, business firms, organizations or individuals for Our own business purposes. You agree that the license You grant to Us includes these rights.') }}`)}</p>
        <p>{tt(`{{ _('12) General. These Terms of Use constitute the entire agreement between You and Us regarding use of the Website. Any failure by Us not to exercise or enforce any legal right or remedy available to us shall not be construed as a waiver of such right or remedy. If any court of competent jurisdiction rules that any provision of these Terms of Use is invalid, then such provision shall be removed from these Terms of Use without affecting the remainder of the Terms of Use and these Terms of Use will continue to be valid and enforceable. These Terms of Use shall be governed under the laws of the State of Delaware without regard to its conflict of laws provisions. Any claim or cause of action You may have against Us must be filed within one (1) year of the date on which the claim or cause of action occurred or such claim or cause of action shall be forever barred.') }}`)}</p>
    </div>
  );
};

export default Terms;
