import tt from '../../utils/tt';
import {getCaUrlFromCookie} from "../../config/helpers";

import awardsImg from '../../images/awards.png';
import logoImg from '../../images/default_inverted.png';

import styles from './Start.module.scss';



const Start = () => {
  const url = getCaUrlFromCookie();

  return (
    <>
      <section className={styles.logoSection}>
        <img className={styles.logoImg} src={logoImg} alt="logo" />
      </section>

      <main className={styles.mainSection}>
        <h1
          className={styles.mainTitle}
          dangerouslySetInnerHTML={{
            __html: tt(`{{ _('%sMatch%s%sMaker%s','<span>','</span>','<span>','</span>') }}`)
          }}
        />
        <p className={styles.mainDescription}>
          {tt(`{{ _('This short quiz will help you choose the best dating service') }}`)}
        </p>
        <a
          className={styles.startButton}
          href={url}
        >
          <span>{tt(`{{ _('Take a Quiz') }}`)}</span>
        </a>
      </main>

      <section className={styles.awardSection}>
        <img className={styles.awardImg} src={awardsImg} alt="awards" />
      </section>
    </>
  );
};

export default Start;
