const tt = (str) => {
  const regForReplaceBraces = /(\{\{\s*\_\(\s*\')|(\'\s*\)\s*\}\})|(\n)|(\s+)/g;
  const regForSplit = /\'\s*\,\s*\'/;

  const arrFromStr = str.trim().replace(regForReplaceBraces, ' ').split(regForSplit);

  if (arrFromStr.length > 1) {
    const itemsArr = arrFromStr.slice(1);

    return arrFromStr[0]
      .split('%s')
      .reduce((acc, item, index) => {
        if (itemsArr[index]) {
          return [...acc, item, itemsArr[index]];
        }

        return [...acc, item];
      }, [])
      .join('')
      .trim();
  }

  return arrFromStr[0].trim();
};

export default tt;
