export const LOGO_URL = 'https://chooseyourdating.com/land/chooseyourdating.com/shared/images/default_inverted.png';

export const DOMAIN_NAMES = {
  localhost: 'localhost',
  chooseYourDating: 'chooseyourdating.com',
}

export const COOKIE_NAMES = {
  pageMainIndex: '__page_main_index',
  caUrl: '__ca_url_chooseyourdating'
}

export const DEFAULT_CA_URL = 'https://chooseyourdating.com/l/97AgFo';