import setCookie from '../utils/setCookie';
import getCookie from "../utils/getCookie";

import {COOKIE_NAMES, DEFAULT_CA_URL, DOMAIN_NAMES} from "./constants";

const checkIsLocalhost = () => /localhost/.test(window.location.origin);

export const getDefaultExpires = () => {
  return new Date(new Date().getTime() + 25920000 * 1000).toUTCString()
}

export const setPageMainIndexToCookie = () => {
  setCookie(COOKIE_NAMES.pageMainIndex, '1', {
    expires: new Date(new Date().getTime() + 25920000 * 1000).toUTCString(),
    domain: checkIsLocalhost() ? DOMAIN_NAMES.localhost : DOMAIN_NAMES.chooseYourDating
  })
}

export const getCaUrlFromCookie = () => {
  const encodedCaUrl = getCookie(COOKIE_NAMES.caUrl)
  if (encodedCaUrl) {
    console.log(encodedCaUrl);
    return JSON.parse(decodeURI(encodedCaUrl));
  }

  return DEFAULT_CA_URL;
};