import tt from '../../utils/tt';

import styles from './Footer.module.scss';

const Footer = ({setFooterLinksState}) => {
  const openFooterLink = (fieldName) => {
      setFooterLinksState((state) => {
          return {...state, [fieldName]: true }
      })
  }
  return (
    <div className={styles.footer}>
        <p onClick={() => {openFooterLink('aboutUs')}}>{tt(`{{ _('About us') }}`)}</p>
        <p onClick={() => {openFooterLink('privacy')}}>{tt(`{{ _('Privacy policy') }}`)}</p>
        <p onClick={() => {openFooterLink('terms')}}>{tt(`{{ _('Terms of use') }}`)}</p>
    </div>
  );
};

export default Footer;
