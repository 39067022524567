import tt from '../../utils/tt';

import closeIcon from '../../images/close.svg';

import styles from './AboutUs.module.scss';

const AboutUs = ({shouldShow, closeFooterLinks}) => {
  return shouldShow && (
    <div className={styles.about}>
        <img className={styles.closeIcon} src={closeIcon} onClick={closeFooterLinks} alt="close" />
        <h2>{tt(`{{ _('About US') }}`)}</h2>
        <p>{tt(`{{ _('Online dating is easier than it seems!') }}`)}</p>
        <p>{tt(`{{ _('Welcome to chooseyourdating.com -  an independent resource that helps you choose the most suitable dating site among the most popular platforms. Detailed breakdowns and accurate ratings are all here!') }}`)}</p>
        <p>{tt(`{{ _('❌ No! We do not invent or collect information somewhere on the Internet.') }}`)}</p>
        <p>{tt(`{{ _('🚀 Yes! Our experts personally register and test each of the presented sites.') }}`)}</p>
        <h3>{tt(`{{ _('Сhoose what suits you best') }}`)}</h3>
        <p>{tt(`{{ _('we check how fast and convenient registration is;
                        we compile lists of real pros and cons of the site;
                        we study paid and free opportunities;
                        we test all available types of Subscriptions;
                        we check how active users are;
                        we make sure that your data will be safe;
                        find out if the support service works 24/7;
                        We are interested in whether the site has a mobile application;
                        we give valuable tips for successful dating.') }}`)}</p>
        <h3>{tt(`{{ _('Just three simple steps to start dating online:') }}`)}</h3>
        <ol>
            <li>
                <p>{tt(`{{ _('Choose a reliable platform - chooseyourdating.com will help you with this.') }}`)}</p>
            </li>
            <li>
                <p>{tt(`{{ _('Fill out a small questionnaire on the preferred platform. Take your time and fill out your profile in as much detail as possible: who are you, who are you looking for, and what do you dream about? This will help you find a suitable couple for you, avoiding pointless correspondence on the topic of people who have other goals and priorities.') }}`)}</p>
            </li>
            <li>
                <p>{tt(`{{ _('Be online and chat with interesting people! Registration is only 50% of success. Do not forget to appear online regularly, upload new photos and respond to messages. Sometimes a letter read on time changes one’s life!') }}`)}</p>
            </li>
        </ol>
        <p>{tt(`{{ _('And remember: there are no stereotypes in the network! Both a man and a woman can be the first to show sympathy. Stickers, gifts, photo hearts, and compatibility check — everything to help you find your soul mate faster.') }}`)}</p>
        <p>{tt(`{{ _('Valuable acquaintances close! Good luck! 🙂') }}`)}</p>
    </div>
  );
};

export default AboutUs;
